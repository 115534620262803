import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Chip,
} from '@mui/material';

const ClientCard = ({ client }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {client.name}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          Domain: {client.domain}
        </Typography>
        <Typography variant="body2">
          Extensions:
          {client.worker && (
            <Chip label="Cloudflare" size="small" sx={{ ml: 1 }} />
          )}
          {client.screen_frog_executions?.length > 0 && (
            <Chip label="Screaming Frog" size="small" sx={{ ml: 1 }} />
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={RouterLink}
          to={`/clients/${client.id}`}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default ClientCard;
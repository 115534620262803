// src/components/PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  console.log('PrivateRoute - isAuthenticated:', isAuthenticated, 'isLoading:', isLoading);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
    console.log('Redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  console.log('Rendering children');
  return children;
};

export default PrivateRoute;
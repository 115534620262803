import React, {useState} from 'react';
import {
    Typography,
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Tooltip,
    CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';

const ScreamingFrogExtension = ({client, onTriggerScan, onDownloadResults, downloadLoading}) => {
    const [isAddingExecution, setIsAddingExecution] = useState(false);
    const [newExecutionUrl, setNewExecutionUrl] = useState('');

    const executions = client.screen_frog_executions || [];

    const handleAddExecution = () => {
        setIsAddingExecution(true);
    };

    const handleCloseDialog = () => {
        setIsAddingExecution(false);
        setNewExecutionUrl('');
    };

    const handleSubmitExecution = () => {
        onTriggerScan(client.id, newExecutionUrl);
        handleCloseDialog();
    };

    return (
        <Paper elevation={2} sx={{mt: 3, p: 2}}>
            <Typography variant="h6" gutterBottom>Screaming Frog Extension</Typography>

            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon/>}
                onClick={handleAddExecution}
                sx={{mb: 2}}
            >
                New Scan
            </Button>

            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Execution Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {executions.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">No scans have been executed yet.</TableCell>
                            </TableRow>
                        ) : (
                            executions.map((execution) => (
                                <TableRow key={execution.id}>
                                    <TableCell>{execution.url}</TableCell>
                                    <TableCell>{execution.status}</TableCell>
                                    <TableCell>{new Date(execution.execution_date).toLocaleString()}</TableCell>
                                    <TableCell>
                                        {execution.status === 'Completed' && execution.s3_path && (
                                            <Tooltip title="Download Results">
                                                <IconButton
                                                    onClick={() => onDownloadResults(execution.s3_path)}
                                                    disabled={downloadLoading}
                                                >
                                                    {downloadLoading ? <CircularProgress size={24}/> : <DownloadIcon/>}
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={isAddingExecution} onClose={handleCloseDialog}>
                <DialogTitle>New Screaming Frog Scan</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="URL to Scan"
                        type="url"
                        fullWidth
                        variant="outlined"
                        value={newExecutionUrl}
                        onChange={(e) => setNewExecutionUrl(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSubmitExecution} variant="contained" color="primary">
                        Start Scan
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default ScreamingFrogExtension;

import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
} from '@react-google-maps/api';

const MapContainer = styled('div')(({ theme }) => ({
  height: '400px',
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

function MapSelector({ onLocationSelected }) {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const searchBoxRef = useRef(null);

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length) {
      const location = places[0].geometry.location;
      const latLng = {
        lat: location.lat(),
        lng: location.lng(),
      };
      setCenter(latLng);
      setMarkerPosition(latLng);
      onLocationSelected(latLng);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDKT6NsEu25cWSgppoe6ZzsJGxr6t9xbcA"
      libraries={['places']}
    >
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={handlePlacesChanged}
      >
        <StyledTextField
          variant="outlined"
          fullWidth
          placeholder="Search location"
        />
      </StandaloneSearchBox>
      <MapContainer>
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={center}
          zoom={markerPosition ? 15 : 2}
        >
          {markerPosition && <Marker position={markerPosition} />}
        </GoogleMap>
      </MapContainer>
    </LoadScript>
  );
}

export default MapSelector;
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from '@mui/material';

const CreateClient = ({ open, onClose, onSubmit }) => {
  const [clientData, setClientData] = useState({
    name: '',
    domain: '',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = clientData.name ? "" : "Name is required";
    tempErrors.domain = clientData.domain ? "" : "Domain is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(clientData);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Client</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="name"
              label="Client Name"
              value={clientData.name}
              onChange={handleChange}
              required
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
            <TextField
              name="domain"
              label="Domain"
              value={clientData.domain}
              onChange={handleChange}
              required
              fullWidth
              error={Boolean(errors.domain)}
              helperText={errors.domain}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateClient;
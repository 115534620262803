// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { login as loginService } from '../services/auth';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isLoading: true,
  });

  const checkAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    setAuthState({
      isAuthenticated: !!token,
      isLoading: false,
    });
    console.log('checkAuth - isAuthenticated:', !!token);
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = async (credentials) => {
    try {
      const response = await loginService(credentials);
      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setAuthState({
          isAuthenticated: true,
          isLoading: false,
        });
        console.log('Login successful - token set');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthState({
      isAuthenticated: false,
      isLoading: false,
    });
    console.log('Logged out - token removed');
  }, []);

  const value = useMemo(
    () => ({ ...authState, login, logout, checkAuth }),
    [authState, checkAuth, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Switch,
    Box,
    Button,
    TextField,
    IconButton,
    Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const OptimizationWorkerExtension = ({
    worker,
    routes,
    isEditing,
    onEditClick,
    onWorkerUpdate,
    onRoutesUpdate,
    onSave,
    onCancel,
    onDeploy,
    onDelete,
    isNewWorker = false
}) => {
    const [editedConfig, setEditedConfig] = useState(worker.config);
    const [editedRoutes, setEditedRoutes] = useState(routes ? routes.routes : []);

    useEffect(() => {
        setEditedConfig(worker.config);
        setEditedRoutes(routes ? routes.routes : []);
    }, [worker, routes]);

    const configItems = [
        { key: 'ENABLE_LOGGING', label: 'Logging' },
        { key: 'ENABLE_EDGE_CACHE', label: 'Edge Cache' },
        { key: 'ENABLE_GEO_HANDLER', label: 'Geo Handler' },
        { key: 'ENABLE_PRELOAD_HINTS', label: 'Preload Hints' },
        { key: 'ENABLE_PLAUSIBLE_SYNC', label: 'Plausible Sync' },
        { key: 'ENABLE_IMAGE_MODIFICATION', label: 'Image Modification' },
        { key: 'ENABLE_VIMEO_MODIFICATION', label: 'Vimeo Modification' },
        { key: 'ENABLE_PLAUSIBLE_ANALYTICS', label: 'Plausible Analytics' },
        { key: 'ENABLE_YOUTUBE_MODIFICATION', label: 'YouTube Modification' },
        { key: 'ENABLE_EXTERNAL_LINK_MODIFICATION', label: 'External Link Modification' },
        { key: 'ENABLE_EXTERNAL_IMAGE_MODIFICATION', label: 'External Image Modification' }
    ];

    const handleConfigChange = (key) => {
        const updatedConfig = {
            ...editedConfig,
            [key]: !editedConfig[key]
        };
        setEditedConfig(updatedConfig);
        onWorkerUpdate({ ...worker, config: updatedConfig });
    };

    const handleAddRoute = () => {
        const updatedRoutes = [...editedRoutes, { pattern: '', zone_id: '' }];
        setEditedRoutes(updatedRoutes);
        onRoutesUpdate(updatedRoutes);
    };

    const handleRouteChange = (index, field, value) => {
        const updatedRoutes = editedRoutes.map((route, i) =>
            i === index ? { ...route, [field]: value } : route
        );
        setEditedRoutes(updatedRoutes);
        onRoutesUpdate(updatedRoutes);
    };

    const handleDeleteRoute = (index) => {
        const updatedRoutes = editedRoutes.filter((_, i) => i !== index);
        setEditedRoutes(updatedRoutes);
        onRoutesUpdate(updatedRoutes);
    };

    return (
        <Paper elevation={2} sx={{ mt: 3, p: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Optimization Worker Extension</Typography>
                {!isNewWorker && !isEditing && (
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onDeploy}
                            sx={{ mr: 1 }}
                        >
                            Deploy
                        </Button>
                        <IconButton onClick={onEditClick}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>

            <Box sx={{ mb: 2 }}>
                <Typography variant="body1">Name: {worker.name}</Typography>
                {worker.status && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                        <Typography variant="body2" color="text.secondary">
                            Status: {worker.status}
                        </Typography>
                        {worker.updated_at && (
                            <Typography variant="body2" color="text.secondary">
                                Last Updated: {new Date(worker.updated_at).toLocaleString()}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>Optimization Settings</Typography>
            <List dense>
                {configItems.map((item) => (
                    <ListItem key={item.key}>
                        <ListItemText primary={item.label} />
                        <Switch
                            edge="end"
                            checked={editedConfig[item.key] || false}
                            onChange={() => handleConfigChange(item.key)}
                            disabled={!isEditing}
                        />
                    </ListItem>
                ))}
            </List>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>Worker Routes</Typography>
            {editedRoutes.map((route, index) => (
                <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Pattern"
                        value={route.pattern}
                        onChange={(e) => handleRouteChange(index, 'pattern', e.target.value)}
                        disabled={!isEditing}
                        size="small"
                        sx={{ mr: 1, flexGrow: 1 }}
                    />
                    <TextField
                        label="Zone ID"
                        value={route.zone_id}
                        onChange={(e) => handleRouteChange(index, 'zone_id', e.target.value)}
                        disabled={!isEditing}
                        size="small"
                        sx={{ mr: 1, flexGrow: 1 }}
                    />
                    {isEditing && (
                        <IconButton onClick={() => handleDeleteRoute(index)} size="small">
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Box>
            ))}
            {isEditing && (
                <Button startIcon={<AddIcon />} onClick={handleAddRoute} sx={{ mt: 1 }}>
                    Add Route
                </Button>
            )}

            {isEditing && !isNewWorker && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onCancel} sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={onSave}>
                        Save Changes
                    </Button>
                </Box>
            )}
        </Paper>
    );
};

export default OptimizationWorkerExtension;
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button, Paper, Snackbar, Alert } from '@mui/material';
import ImageUploader from '../components/ImageUploader';
import MapSelector from '../components/MapSelector';
import { geotagImages } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

function GeotaggingPage() {
  const [images, setImages] = useState([]);
  const [location, setLocation] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleImagesSelected = (selectedImages) => {
    setImages(selectedImages);
  };

  const handleLocationSelected = (latLng) => {
    setLocation(latLng);
  };

  const handleSubmit = async () => {
    if (!images.length || !location) {
      setSnackbar({ open: true, message: 'Please select images and a location.', severity: 'error' });
      return;
    }

    const formData = new FormData();
    images.forEach((image) => formData.append('images', image));
    formData.append('lat', location.lat);
    formData.append('lng', location.lng);

    try {
      const response = await geotagImages(formData);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'geotagged_images.zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSnackbar({ open: true, message: 'Images geotagged successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error geotagging images:', error);
      setSnackbar({ open: true, message: 'Error geotagging images. Please try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <StyledPaper>
      <Typography variant="h4" gutterBottom>Image Geotagging Tool</Typography>
      <Section>
        <Typography variant="h6" gutterBottom>Upload Images</Typography>
        <ImageUploader onImagesSelected={handleImagesSelected} />
      </Section>
      <Section>
        <Typography variant="h6" gutterBottom>Select Location</Typography>
        <MapSelector onLocationSelected={handleLocationSelected} />
      </Section>
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!images.length || !location}>
        Geotag Images
      </Button>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
}

export default GeotaggingPage;
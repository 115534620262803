import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography,
    CircularProgress,
    Box,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert,
    Card,
    CardContent,
    CardActions,
    Backdrop,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import {
    getClient,
    updateClient,
    createWorker,
    updateWorker,
    deleteWorker,
    deployWorker,
    updateRoutes,
    triggerScreamingFrogScan,
    getScreamingFrogDownloadUrl
} from '../services/api';
import OptimizationWorkerExtension from "../components/OptimizationWorkerExtension";
import ScreamingFrogExtension from "../components/ScreamingFrogExtension";

const ClientDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditingClientInfo, setIsEditingClientInfo] = useState(false);
    const [isEditingWorker, setIsEditingWorker] = useState(false);
    const [isAddingWorker, setIsAddingWorker] = useState(false);
    const [newWorkerName, setNewWorkerName] = useState('');
    const [newWorkerConfig, setNewWorkerConfig] = useState({});
    const [newWorkerRoutes, setNewWorkerRoutes] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const fetchClientDetails = useCallback(async () => {
        try {
            const response = await getClient(id);
            setClient(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch client details');
            setLoading(false);
            setSnackbar({ open: true, message: 'Failed to fetch client details', severity: 'error' });
        }
    }, [id]);

    useEffect(() => {
        fetchClientDetails();
    }, [fetchClientDetails]);

    const handleClientInfoEditClick = () => {
        setIsEditingClientInfo(true);
    };

    const handleClientInfoSaveClick = async () => {
        try {
            await updateClient(client.id, { name: client.name, domain: client.domain });
            setIsEditingClientInfo(false);
            setSnackbar({ open: true, message: 'Client updated successfully', severity: 'success' });
            fetchClientDetails();
        } catch (err) {
            setError('Failed to update client');
            setSnackbar({ open: true, message: 'Failed to update client', severity: 'error' });
        }
    };

    const handleClientInfoCancelClick = () => {
        setIsEditingClientInfo(false);
        fetchClientDetails();
    };

    const handleClientNameChange = (event) => {
        setClient(prev => ({ ...prev, name: event.target.value }));
    };

    const handleClientDomainChange = (event) => {
        setClient(prev => ({ ...prev, domain: event.target.value }));
    };

    const handleWorkerEditClick = () => {
        setIsEditingWorker(true);
    };

    const handleWorkerUpdate = async (updatedWorker) => {
        setClient(prev => ({ ...prev, worker: updatedWorker }));
    };

    const handleWorkerSave = async () => {
        try {
            await updateWorker(client.worker.id, client.worker);
            await updateRoutes(client.routes.id, { routes: client.routes.routes });
            setSnackbar({ open: true, message: 'Optimization Worker Extension updated successfully', severity: 'success' });
            setIsEditingWorker(false);
            fetchClientDetails();
        } catch (err) {
            setError('Failed to update Optimization Worker Extension');
            setSnackbar({ open: true, message: 'Failed to update Optimization Worker Extension', severity: 'error' });
        }
    };

    const handleWorkerCancel = () => {
        setIsEditingWorker(false);
        fetchClientDetails();
    };

    const handleWorkerDeploy = async (workerId) => {
        try {
            await deployWorker(workerId);
            setSnackbar({ open: true, message: 'Successfully triggered Optimization Worker Extension deployment', severity: 'success' });
            fetchClientDetails();
        } catch (err) {
            setError('Failed to deploy Optimization Worker Extension');
            setSnackbar({ open: true, message: 'Failed to deploy Optimization Worker Extension', severity: 'error' });
        }
    };

    const handleWorkerDelete = async (workerId) => {
        try {
            await deleteWorker(workerId);
            setSnackbar({ open: true, message: 'Optimization Worker Extension deleted successfully', severity: 'success' });
            fetchClientDetails();
        } catch (err) {
            setError('Failed to delete Optimization Worker Extension');
            setSnackbar({ open: true, message: 'Failed to delete Optimization Worker Extension', severity: 'error' });
        }
    };

    const handleRoutesUpdate = async (updatedRoutes) => {
        setClient(prev => ({ ...prev, routes: { ...prev.routes, routes: updatedRoutes } }));
    };

    const handleAddOptimizationWorkerExtensionClick = () => {
        setIsAddingWorker(true);
        setNewWorkerName('');
        setNewWorkerConfig({
            ENABLE_LOGGING: false,
            ENABLE_EDGE_CACHE: false,
            ENABLE_GEO_HANDLER: false,
            ENABLE_PRELOAD_HINTS: false,
            ENABLE_PLAUSIBLE_SYNC: false,
            ENABLE_IMAGE_MODIFICATION: false,
            ENABLE_VIMEO_MODIFICATION: false,
            ENABLE_PLAUSIBLE_ANALYTICS: false,
            ENABLE_YOUTUBE_MODIFICATION: false,
            ENABLE_EXTERNAL_LINK_MODIFICATION: false,
            ENABLE_EXTERNAL_IMAGE_MODIFICATION: false
        });
        setNewWorkerRoutes([{ pattern: '', zone_id: '' }]);
    };

    const handleAddWorkerClose = () => {
        setIsAddingWorker(false);
        setNewWorkerName('');
        setNewWorkerConfig({});
        setNewWorkerRoutes([]);
    };

    const handleAddWorkerSave = async () => {
        try {
            const response = await createWorker(client.id, {
                name: newWorkerName,
                config: newWorkerConfig,
                routes: newWorkerRoutes
            });
            setIsAddingWorker(false);
            setSnackbar({ open: true, message: 'New Optimization Worker Extension added successfully', severity: 'success' });
            setClient(prevClient => ({
                ...prevClient,
                worker: response.data.worker,
                routes: response.data.routes
            }));
        } catch (err) {
            setError('Failed to add new Optimization Worker Extension');
            setSnackbar({ open: true, message: 'Failed to add new Optimization Worker Extension', severity: 'error' });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleBackToClients = () => {
        navigate('/clients');
    };

    const handleTriggerScan = async (clientId, url) => {
        try {
            await triggerScreamingFrogScan(clientId, url);
            setSnackbar({ open: true, message: 'Scan triggered successfully', severity: 'success' });
            fetchClientDetails();
        } catch (err) {
            setError('Failed to trigger scan');
            setSnackbar({ open: true, message: 'Failed to trigger scan', severity: 'error' });
        }
    };

    const handleDownloadResults = async (s3Path) => {
        setDownloadLoading(true);
        try {
            const response = await getScreamingFrogDownloadUrl(s3Path);
            if (response.data && response.data.download_url) {
                const link = document.createElement('a');
                link.href = response.data.download_url;
                const filename = s3Path.split('/').pop() || 'screaming_frog_results.zip';
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                throw new Error('Download URL not found in response');
            }
        } catch (err) {
            setError('Failed to get download URL');
            setSnackbar({ open: true, message: 'Failed to get download URL', severity: 'error' });
        } finally {
            setDownloadLoading(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return (
            <Box>
                <Typography color="error">{error}</Typography>
                <Button onClick={handleBackToClients}>Back to Clients</Button>
            </Box>
        );
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4">Client Details</Typography>
                <Button onClick={handleBackToClients}>
                    Back to Clients
                </Button>
            </Box>
            {client && (
                <>
                    <Card elevation={3} sx={{ mb: 3 }}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h5">Basic Client Information</Typography>
                                <IconButton onClick={handleClientInfoEditClick} disabled={isEditingClientInfo}>
                                    <EditIcon />
                                </IconButton>
                            </Box>
                            {isEditingClientInfo ? (
                                <>
                                    <TextField
                                        fullWidth
                                        label="Client Name"
                                        value={client.name}
                                        onChange={handleClientNameChange}
                                        margin="normal"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Domain"
                                        value={client.domain}
                                        onChange={handleClientDomainChange}
                                        margin="normal"
                                    />
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1">Name: {client.name}</Typography>
                                    <Typography variant="body1">Domain: {client.domain}</Typography>
                                </>
                            )}
                            <Typography variant="body2">
                                Created: {new Date(client.created_at).toLocaleString()}
                            </Typography>
                            <Typography variant="body2">
                                Last Updated: {new Date(client.updated_at).toLocaleString()}
                            </Typography>
                        </CardContent>
                        {isEditingClientInfo && (
                            <CardActions>
                                <Button variant="contained" color="primary" onClick={handleClientInfoSaveClick}>
                                    Save Client Details
                                </Button>
                                <Button variant="outlined" onClick={handleClientInfoCancelClick}>
                                    Cancel
                                </Button>
                            </CardActions>
                        )}
                    </Card>

                    {client.worker ? (
                        <OptimizationWorkerExtension
                            worker={client.worker}
                            routes={client.routes}
                            isEditing={isEditingWorker}
                            onEditClick={handleWorkerEditClick}
                            onWorkerUpdate={handleWorkerUpdate}
                            onRoutesUpdate={handleRoutesUpdate}
                            onSave={handleWorkerSave}
                            onCancel={handleWorkerCancel}
                            onDeploy={() => handleWorkerDeploy(client.worker.id)}
                            onDelete={() => handleWorkerDelete(client.worker.id)}
                        />
                    ) : (
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleAddOptimizationWorkerExtensionClick}
                            sx={{ mt: 2, mb: 3 }}
                        >
                            Add Optimization Worker Extension
                        </Button>
                    )}

                    <ScreamingFrogExtension
                        client={client}
                        onTriggerScan={handleTriggerScan}
                        onDownloadResults={handleDownloadResults}
                        downloadLoading={downloadLoading}
                    />
                </>
            )}

            <Dialog
                open={isAddingWorker}
                onClose={handleAddWorkerClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Add New Optimization Worker Extension</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Worker Name"
                        fullWidth
                        value={newWorkerName}
                        onChange={(e) => setNewWorkerName(e.target.value)}
                    />
                    <OptimizationWorkerExtension
                        worker={{ name: newWorkerName, config: newWorkerConfig }}
                        routes={{ routes: newWorkerRoutes }}
                        isEditing={true}
                        onWorkerUpdate={(updatedWorker) => setNewWorkerConfig(updatedWorker.config)}
                        onRoutesUpdate={(updatedRoutes) => setNewWorkerRoutes(updatedRoutes)}
                        isNewWorker={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddWorkerClose}>Cancel</Button>
                    <Button onClick={handleAddWorkerSave} variant="contained" color="primary">
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={downloadLoading}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CircularProgress color="inherit" />
                    <Typography variant="h6" sx={{ mt: 2 }}>Generating download link...</Typography>
                </Box>
            </Backdrop>

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ClientDetails;
import axios from '../api/axios';

// Existing functions
export const getClients = () => axios.get('/clients/');
export const getClient = (id) => axios.get(`/clients/${id}/`);
export const createClient = (data) => axios.post('/clients/', data);
export const updateClient = (id, data) => axios.patch(`/clients/${id}/`, data);

// Worker functions
export const createWorker = (clientId, data) => axios.post('/worker-configurations/create_for_client/', {
    client_id: clientId,
    name: data.name,
    config: data.config,
    routes: data.routes
});

export const updateWorker = (id, data) => axios.patch(`/worker-configurations/${id}/`, data);
export const deleteWorker = (id) => axios.delete(`/worker-configurations/${id}/`);
export const deployWorker = (workerId) => axios.post('/worker-configurations/deploy_worker/', { workerId });

// Routes functions
export const updateRoutes = (id, data) => axios.patch(`/client-routes/${id}/`, data);

export const triggerScreamingFrogScan = (clientId, url) => axios.post(`/screaming-frog/trigger_scan/`, { client_id: String(clientId), url });

export const getScreamingFrogDownloadUrl = (s3Path) =>
  axios.get(`/screaming-frog-executions/download_results/?s3_path=${encodeURIComponent(s3Path)}`);


export const geotagImages = (formData) => axios.post('/geotag/geotag_images/', formData, {
  responseType: 'blob',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});


import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
}));

function ImageUploader({ onImagesSelected }) {
  const [files, setFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
    onImagesSelected(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  return (
    <StyledPaper {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography variant="body1">Drag & drop images here, or click to select files</Typography>
      {files.length > 0 && (
        <List>
          {files.map((file) => (
            <ListItem key={file.path}>
              <ListItemText primary={file.path} />
            </ListItem>
          ))}
        </List>
      )}
    </StyledPaper>
  );
}

export default ImageUploader;